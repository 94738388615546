import { LinkType } from 'components/Link';

export enum LinkVariant {
  MAIN = 'main',
  DROPDOWN = 'dropdown',
}

export interface DesktopNavigationProps {
  link: LinkType[];
  returnText: string;
  className?: string;
}
