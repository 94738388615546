import React, { FC, useMemo } from 'react';
import { GatsbyImage, getImage, IGatsbyImageData, withArtDirection } from 'gatsby-plugin-image';
import classNames from 'classnames';

import { BREAKPOINTS } from './constants';
import { getArtDirectedImages } from './utils';

import { ImageBreakpoint, ImageDataLike, ImageProps, ResponsiveImage } from './models.d';

import './Image.scss';

const { MD, LG } = BREAKPOINTS;

const defaultBreakpoints: ImageBreakpoint[] = [
  { alias: 'tablet', media: `(min-width: ${MD}px) and (max-width: ${LG - 1}px)` },
  { alias: 'desktop', media: `(min-width: ${LG}px)` },
];

const Image: FC<ImageProps> = ({
  className,
  imageData,
  alt,
  breakpoints = defaultBreakpoints,
  ...restProps
}) => {
  const imageClasses = classNames('image', className);
  const isArtDirected = Object.keys(imageData).includes('mobile');
  const mainImage = isArtDirected
    ? getImage((imageData as ResponsiveImage).mobile)
    : getImage(imageData as ImageDataLike);

  const images = withArtDirection(
    mainImage as IGatsbyImageData,
    getArtDirectedImages(breakpoints, imageData as ResponsiveImage)
  );

  return useMemo(
    () => (
      <div className={imageClasses} data-testid="image-item">
        <GatsbyImage image={images} alt={alt === '[DECORATIVE]' ? '' : alt} {...restProps} />
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [className]
  );
};

export const createImageWithBreakpoints =
  (breakpoints: ImageBreakpoint[]): FC<ImageProps> =>
  (props: ImageProps) =>
    <Image {...props} breakpoints={breakpoints} />;

export default Image;
