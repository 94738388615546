import React, { FC } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';

import Image from 'common/Image';

import { useCurrentLang } from 'hooks/useCurrentLang';

import { LogoPropsType } from './models';

const Logo: FC<LogoPropsType> = ({ image }) => {
  const {
    currentLang: [currentLangCode],
  } = useCurrentLang();

  const {
    allHomePage: { nodes: homepageNodes },
  } = useStaticQuery(graphql`
    {
      allHomePage {
        nodes {
          url
          lang
        }
      }
    }
  `);

  const { url } = homepageNodes.find(({ lang }) => lang === currentLangCode);

  return (
    <Link to={url}>
      <Image {...image.imageStructure} className="logo__img" />
    </Link>
  );
};

export default Logo;
