export const UseCurrentLangMock = {
  useStaticQueryReturn: {
    allHomePage: {
      nodes: [
        {
          id: '83720b31-1942-5a1d-b380-c51b09005cc1',
          lang: 'en-gb',
          url: '/',
        },
      ],
    },
  },
};
