import { isEmpty } from 'lodash';

import {
  Align,
  AlignBreakpoints,
  Padding,
  Position,
  Size,
  SizeBreakpoints,
  TypographyProps,
} from 'common/Typography';

type TypographySizePickerType = {
  fontSizePicker?: Size | null;
};

type TypographySizeBreakpointsType = {
  breakpoint?: BreakpointPickerType;
  sizePicker?: TypographySizePickerType;
};

type TypographySizeType = {
  sizePicker?: TypographySizePickerType;
  sizeBreakpoints?: TypographySizeBreakpointsType[] | [];
};

type TypographyPaddingPickerType = {
  paddingPicker?: Padding | null;
};

type TypographyPaddingPositionPickerType = {
  top?: TypographyPaddingPickerType;
  right?: TypographyPaddingPickerType;
  bottom?: TypographyPaddingPickerType;
  left?: TypographyPaddingPickerType;
};

type TypographyPositionBreakpointsType = {
  breakpoint?: BreakpointPickerType;
  positionPicker?: TypographyPaddingPositionPickerType;
};

type TypographyPaddingType = {
  positionPicker?: TypographyPaddingPositionPickerType;
  positionBreakpoints?: TypographyPositionBreakpointsType[];
};

type TypographyAlignPickerType = {
  position?: Align | null;
};

type TypographyAlignBreakpointsType = {
  breakpoint?: BreakpointPickerType;
  alignPicker?: TypographyAlignPickerType;
};

type TypographyAlignType = {
  alignPicker?: TypographyAlignPickerType;
  alignBreakpoints?: TypographyAlignBreakpointsType[] | [];
};

type BreakpointPickerType = {
  breakpointPicker?: UiSettings.BreakpointsType | null;
};

export type TypographyStructureType = {
  size?: TypographySizeType | null;
  padding?: TypographyPaddingType | null;
  align?: TypographyAlignType | null;
  color?: UiSettings.ColorPickerType | null;
  lineHeight?: TypographyProps['lineHeight'] | null;
  weight?: TypographyProps['weight'] | null;
  italic?: TypographyProps['italic'] | null;
  animationVariant?: TypographyProps['animationVariant'] | null;
  dangerouslySetInnerHTML?: TypographyProps['dangerouslySetInnerHTML'] | null;
  as?: TypographyProps['as'] | null;
  structureAlias?: 'typography' | null;
};

const convertTypographySize = (size?: TypographyStructureType['size']): TypographyProps['size'] => {
  if (!size?.sizeBreakpoints || !size?.sizePicker) return undefined;

  const baseSize = !size.sizePicker.fontSizePicker
    ? undefined
    : Number(size.sizePicker.fontSizePicker);

  if (!isEmpty(size?.sizeBreakpoints)) {
    const breakpoints = size.sizeBreakpoints.map(({ breakpoint, sizePicker }) => {
      if (!breakpoint?.breakpointPicker) return null;

      return {
        [breakpoint.breakpointPicker]: Number(sizePicker?.fontSizePicker),
      };
    });

    const sizeWithBreakpoints = {
      ...Object.assign({ base: baseSize }, ...breakpoints),
    } as SizeBreakpoints;

    return sizeWithBreakpoints;
  }

  return baseSize;
};

const convertTypographyAlign = (
  align?: TypographyStructureType['align']
): TypographyProps['align'] => {
  if (!align?.alignBreakpoints || !align?.alignPicker) return undefined;

  const baseAlign = align.alignPicker.position ?? undefined;

  if (!isEmpty(align?.alignBreakpoints)) {
    const breakpoints = align.alignBreakpoints.map(({ breakpoint, alignPicker }) => {
      if (!breakpoint?.breakpointPicker) return null;

      return {
        [breakpoint.breakpointPicker]: alignPicker?.position,
      };
    });

    const alignWithBreakpoints = {
      ...Object.assign({ base: baseAlign }, ...breakpoints),
    } as AlignBreakpoints;

    return alignWithBreakpoints;
  }

  return baseAlign;
};

const convertPadding = (
  padding?: TypographyStructureType['padding']
): TypographyProps['padding'] => {
  if (!padding?.positionBreakpoints || !padding?.positionPicker) return undefined;

  const positions = Object.keys(padding.positionPicker);

  return positions.reduce((acc, curr) => {
    const newBreakpoints = padding?.positionBreakpoints?.map(({ breakpoint, positionPicker }) => {
      if (!positionPicker?.[curr as Position]?.paddingPicker || !breakpoint?.breakpointPicker)
        return null;

      return {
        [breakpoint.breakpointPicker]: positionPicker[curr as Position]?.paddingPicker,
      };
    });

    return {
      ...acc,
      [curr]: Object.assign(
        {
          base: padding?.positionPicker?.[curr]?.paddingPicker,
        },
        ...(newBreakpoints as TypographyPositionBreakpointsType[])
      ),
    };
  }, {});
};

export const convertTypography = (
  customTypography?: TypographyProps | TypographyStructureType | null,
  defaultTypography?: TypographyStructureType
): TypographyProps => {
  const isTypographyStructure = customTypography?.structureAlias === 'typography';

  return {
    align:
      (isTypographyStructure
        ? convertTypographyAlign(customTypography?.align as TypographyStructureType['align'])
        : (customTypography?.align as TypographyProps['align'])) ||
      convertTypographyAlign(defaultTypography?.align),
    size:
      (isTypographyStructure
        ? convertTypographySize(customTypography?.size as TypographyStructureType['size'])
        : (customTypography?.size as TypographyProps['size'])) ||
      convertTypographySize(defaultTypography?.size),
    padding:
      (isTypographyStructure
        ? convertPadding(customTypography?.padding as TypographyStructureType['padding'])
        : (customTypography?.padding as TypographyProps['padding'])) ||
      convertPadding(defaultTypography?.padding),
    weight: customTypography?.weight || defaultTypography?.weight || undefined,
    italic: customTypography?.italic || defaultTypography?.italic || undefined,
    lineHeight: customTypography?.lineHeight || defaultTypography?.lineHeight || undefined,
    color: customTypography?.color || defaultTypography?.color || undefined,
    animationVariant:
      customTypography?.animationVariant || defaultTypography?.animationVariant || undefined,
    as: customTypography?.as || defaultTypography?.as || undefined,
  };
};
