import React, { FC } from 'react';
import classNames from 'classnames';

import Dropdown from 'components/Dropdown';
import Link, { LinkProps, LinkType } from 'components/Link';

import { DesktopNavigationProps, LinkVariant } from './models.d';

import './DesktopNavigation.scss';

const { MAIN, DROPDOWN } = LinkVariant;

const DesktopNavigation: FC<DesktopNavigationProps> = ({ link, className, returnText }) => {
  const desktopNavigationClasses = classNames('desktop-navigation', className);

  const renderLink = (items: LinkType, type: LinkVariant = MAIN): JSX.Element => {
    const { label, url } = items;
    const linkProps = (): LinkProps | null => {
      switch (type) {
        case MAIN:
          return {
            className: 'desktop-navigation__item',
            activeClassName: 'desktop-navigation--active',
            size: { base: 14, xxl: 16 },
          };
        case DROPDOWN:
          return {
            activeClassName: 'desktop-navigation--active',
            size: { base: 14, xxl: 16 },
          };
        default:
          return null;
      }
    };

    const isPartiallyActive = ({ isPartiallyCurrent, isCurrent }) =>
      isPartiallyCurrent && isCurrent
        ? { className: ' desktop-navigation__item desktop-navigation--active' }
        : {};

    return (
      <Link key={label} url={url} {...linkProps()} ariaLabel={label} getProps={isPartiallyActive}>
        {label}
      </Link>
    );
  };

  const renderDropdown = (items: LinkType): JSX.Element | null => {
    if (!items.subLinks) return null;
    const { subLinks, label } = items;
    const dropdownContent = subLinks
      .filter((sublink) => sublink.label !== returnText)
      .map((item) => renderLink(item, DROPDOWN));

    return (
      <Dropdown
        key={label}
        button={renderLink(items, DROPDOWN)}
        content={dropdownContent}
        className="desktop-navigation__item"
      />
    );
  };

  return (
    <nav className={desktopNavigationClasses} data-testid="desktop-navigation">
      {link.map((item) => (item.subLinks ? renderDropdown(item) : renderLink(item)))}
    </nav>
  );
};

export default DesktopNavigation;
