import { graphql, useStaticQuery } from 'gatsby';

import { DefaultButtonUIType } from 'components/Button';

type UISettingsType = {
  uiSettings: {
    buttonVariants: DefaultButtonUIType[];
  };
};

export const useUISettings = () =>
  useStaticQuery<UISettingsType>(
    graphql`
      query ButtonVariantsQuery {
        uiSettings {
          buttonVariants {
            ...ButtonUIFragment
          }
        }
      }
    `
  );
