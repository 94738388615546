import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import FadeIn, { FADEIN } from 'framers/FadeIn';

import { Container } from 'layout';
import Typography from 'common/Typography';
import Button from 'components/Button';

import { MobileAppPromoBannerStaticQueryType } from './models';

import './MobileAppPromoBanner.scss';

const MobileAppPromoBanner = () => {
  const { mobileAppPromoBanner } = useStaticQuery<MobileAppPromoBannerStaticQueryType>(graphql`
    query MobileAppPromoBannerQuery {
      mobileAppPromoBanner {
        ...MobileAppPromoBannerFragment
      }
    }
  `);

  if (!mobileAppPromoBanner) return null;

  const { appLogoButton, ingress, appStoreButtons } = mobileAppPromoBanner;

  const { DOWN } = FADEIN;

  return (
    <Container>
      <div className="mobile-app-promo-banner" data-testid="mobile-app-promo-banner">
        {appLogoButton ? (
          <div className="mobile-app-promo-banner__app-logo">
            <FadeIn>
              <Button {...appLogoButton} imageClassName="mobile-app-promo-banner__app-logo-image" />
            </FadeIn>
          </div>
        ) : null}

        {ingress ? (
          <div className="mobile-app-promo-banner__ingress">
            <Typography align="center" animationVariant={DOWN}>
              {ingress}
            </Typography>
          </div>
        ) : null}

        {appStoreButtons ? (
          <div className="mobile-app-promo-banner__app-store-buttons">
            {appStoreButtons.map((button) => (
              <FadeIn key={button?.label || button?.ariaLabel}>
                <Button {...button} imageClassName="mobile-app-promo-banner__app-store-logo" />
              </FadeIn>
            ))}
          </div>
        ) : null}
      </div>
    </Container>
  );
};

export default MobileAppPromoBanner;
