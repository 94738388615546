import React, { FC } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import FadeIn, { FADEIN } from 'framers/FadeIn';

import { Container } from 'layout';
import Image from 'common/Image';
import Typography from 'common/Typography';
import Link, { LinkProps, LinkType } from 'components/Link';

import { useCurrentLang } from 'hooks/useCurrentLang';
import { useNavigationQuery } from 'hooks/useNavigationQuery';

import { LinkVariant } from './models.d';

import './Footer.scss';

const { TOP_PRIMARY, TOP_SECONDARY } = LinkVariant;

const { DOWN } = FADEIN;

const Footer: FC = () => {
  const { currentLang } = useCurrentLang();
  const {
    allFooter: { nodes },
  } = useNavigationQuery();
  const [currentLangCode] = currentLang;
  const footerData = nodes?.find(({ lang }) => lang === currentLangCode);
  const { contactSection, link } = footerData;

  const { logo, contactUs, label: info } = contactSection;

  const renderLink = (items: LinkType, type: LinkVariant = TOP_PRIMARY) => {
    const { label, url } = items;

    const linkProps = (): LinkProps | null => {
      switch (type) {
        case TOP_PRIMARY:
          return {
            size: { base: 14 },
            padding: { bottom: { base: 'sm' }, top: { base: 'sm' } },
            url,
          };
        case TOP_SECONDARY:
          return {
            size: 14,
            padding: { bottom: { base: 'sm' }, top: { base: 'sm' } },
            url,
          };
        default:
          return null;
      }
    };

    return (
      <Link key={label} {...linkProps()} className="color--grey">
        {label}
      </Link>
    );
  };

  return (
    <Container
      element="footer"
      fluid
      gutter
      className="footer background--white"
      data-testid="footer-item"
    >
      <Container element="div" className="footer__wrapper">
        <div className="footer__container">
          <div className="footer__logos">
            {logo?.map((item) => {
              if (!item) {
                return null;
              }
              const {
                imageStructure: { imageData, alt },
              } = item;

              return (
                <FadeIn customClass="footer__logo" key={alt}>
                  <GatsbyLink to={item.brandLogoLink[0].url} title={alt}>
                    <Image alt={alt} imageData={imageData} />
                  </GatsbyLink>
                </FadeIn>
              );
            })}
          </div>
          <Typography
            className="footer__contact color--grey"
            size={14}
            dangerouslySetInnerHTML={contactUs}
            animationVariant={DOWN}
          />
          <Typography
            className="footer__info color--grey"
            size={14}
            dangerouslySetInnerHTML={info}
            animationVariant={DOWN}
          />
        </div>
        <div className="footer__container  footer__container--links">
          {link.map((item: LinkType) => (
            <div key={item.label} className="footer__link">
              <FadeIn>
                {renderLink(item, TOP_PRIMARY)}
                {item.subLinks?.map((subLink) => renderLink(subLink, TOP_SECONDARY))}
              </FadeIn>
            </div>
          ))}
        </div>
      </Container>
    </Container>
  );
};

export default Footer;
