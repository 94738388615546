import React, { FC } from 'react';
import { motion } from 'framer-motion';

import { returnFadeInProps } from './utils';

import { FADEIN, FadeInOptionsProps, FadeInProps } from './models.d';

const FadeIn: FC<FadeInProps> = ({
  children,
  customClass,
  animationVariant = FADEIN.DOWN,
  layout,
}) => {
  if (!children) return null;

  const { initial, transition, viewport, whileInView }: FadeInOptionsProps =
    returnFadeInProps(animationVariant);

  return animationVariant === FADEIN.NONE ? (
    <>{children}</>
  ) : (
    <motion.div className={customClass} {...{ viewport, initial, transition, whileInView, layout }}>
      {children}
    </motion.div>
  );
};

export default FadeIn;
