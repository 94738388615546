import { LinkType } from 'components/Link';

export enum LinkVariant {
  MAIN = 'main',
  SUBMENU = 'subMenu',
  RETURN = 'return',
}

export interface MobileNavigationProps {
  link: LinkType[];
  returnText: string;
  isOpen?: boolean | null;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  isSubMenuOpen?: boolean;
  setIsSubMenuOpen?: Dispatch<SetStateAction<boolean>>;
  className?: string;
}
