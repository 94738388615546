export enum FADEIN {
  DOWN = 'down',
  LISTING = 'listing',
  NONE = 'none',
}

export type FadeInAnimation = { opacity: number; y: number };

export interface FadeInOptionsProps {
  initial: FadeInAnimation;
  whileInView: FadeInAnimation;
  viewport: { once: boolean };
  transition: { duration: number; delay: number };
}

export interface FadeInProps {
  children: React.ReactNode;
  customClass?: string;
  id?: string;
  layout?: boolean;
  animationVariant?: FADEIN;
}
