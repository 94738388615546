import { graphql, useStaticQuery } from 'gatsby';

export const useNavigationQuery = () =>
  useStaticQuery(graphql`
    {
      allNavigation {
        nodes {
          lang
          link {
            ...NavigationLinkFragment
          }
          returnText
        }
      }
      allFooter {
        nodes {
          lang
          contactSection {
            ...ContactSectionFragment
          }
          link {
            ...NavigationLinkFragment
          }
        }
      }
    }
  `);
