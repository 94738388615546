import React, { FC } from 'react';
import classNames from 'classnames';

import Image from 'common/Image';
import NavBase from 'common/NavBase';
import Typography from 'common/Typography';

import { convertTypography } from 'utils/typography';
import { useUISettings } from 'hooks/useUISettings';

import { ButtonProps, ButtonVariantType, DefaultButtonUIType } from './models.d';

import './Button.scss';

const Button: FC<ButtonProps> = ({
  ui,
  children,
  disabled,
  className,
  onClick,
  url,
  ariaLabel,
  image,
  imageClassName = '',
}) => {
  const {
    uiSettings: { buttonVariants },
  } = useUISettings();

  const getVariant = (buttonVariant: ButtonVariantType): DefaultButtonUIType | undefined =>
    buttonVariants.find(({ variant: defaultVariant }) => defaultVariant === buttonVariant);

  const defaultButton = getVariant(ui?.variant);

  const buttonClasses = classNames('button', className, {
    [`button--${ui?.variant}`]: ui?.variant,
    [`button--space-${ui?.space || defaultButton?.space}`]: ui?.space || defaultButton?.space,
    'button--disabled': disabled,
  });

  const backgroundElementClassName = classNames('button__background', {
    [`button--${ui?.variant}`]: ui?.variant,
    [`background--${ui?.backgroundColor || defaultButton?.backgroundColor}`]:
      ui?.backgroundColor || defaultButton?.backgroundColor,
  });

  const navBaseProps = { onClick, url, ariaLabel };

  return (
    <NavBase className={buttonClasses} {...navBaseProps} data-testid="button">
      <div className={backgroundElementClassName} />
      <Typography
        as="div"
        {...(convertTypography(ui?.typography, defaultButton?.typography) || null)}
        className="button__content"
      >
        {children}
        {image ? (
          <Image className={imageClassName} objectFit="contain" {...image.imageStructure} />
        ) : null}
      </Typography>
    </NavBase>
  );
};

export default Button;
