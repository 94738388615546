export const FooterNavigationMock = {
  useStaticQueryReturn: {
    allFooter: {
      nodes: [
        {
          contactSection: [
            {
              label:
                '<p>Fybogel Hi-Fibre Orange Granules and Fybogel Hi-Fibre Lemon Granules, Contains Ispaghula husk 3.5g. Indications: Treatment of patients requiring a high fibre regimen: e.g. relief of constipation, including constipation in pregnancy and the maintenance of regularity; for the management of bowel function in patients with haemorrhoids. Medicines can affect the unborn baby. Always consult a doctor or pharmacist before taking any medicine in pregnancy. Senokot 7.5mg Tablets Adult, Senokot Max Strength Tablets Adult, and Senokot 7.5mg/5ml Syrup 12 Years Plus, Contains Senna. For the relief of short term, occasional constipation. Always read the label. Senocalm is a medical device. Always read the label. RB-M-3150o9</p>',
              logo: [
                {
                  imageStructure: {
                    altText: null,
                    imageData: {
                      desktop: {
                        childImageSharp: {
                          gatsbyImageData: {
                            layout: 'constrained',
                            backgroundColor: '#f8f8f8',
                            images: {
                              fallback: {
                                src: '/static/e363ee14a676c0ddc8ccd8d32d73dc73/d8057/55413227825e1ab0118b05a434b7a5518df4dbad.webp',
                                srcSet:
                                  '/static/e363ee14a676c0ddc8ccd8d32d73dc73/2fa99/55413227825e1ab0118b05a434b7a5518df4dbad.webp 25w,\n/static/e363ee14a676c0ddc8ccd8d32d73dc73/dbc4a/55413227825e1ab0118b05a434b7a5518df4dbad.webp 50w,\n/static/e363ee14a676c0ddc8ccd8d32d73dc73/d8057/55413227825e1ab0118b05a434b7a5518df4dbad.webp 100w',
                                sizes: '(min-width: 100px) 100px, 100vw',
                              },
                              sources: [],
                            },
                            width: 100,
                            height: 100,
                          },
                        },
                      },
                      mobile: {
                        childImageSharp: {
                          gatsbyImageData: {
                            layout: 'constrained',
                            backgroundColor: '#f8f8f8',
                            images: {
                              fallback: {
                                src: '/static/e363ee14a676c0ddc8ccd8d32d73dc73/d8057/55413227825e1ab0118b05a434b7a5518df4dbad.webp',
                                srcSet:
                                  '/static/e363ee14a676c0ddc8ccd8d32d73dc73/2fa99/55413227825e1ab0118b05a434b7a5518df4dbad.webp 25w,\n/static/e363ee14a676c0ddc8ccd8d32d73dc73/dbc4a/55413227825e1ab0118b05a434b7a5518df4dbad.webp 50w,\n/static/e363ee14a676c0ddc8ccd8d32d73dc73/d8057/55413227825e1ab0118b05a434b7a5518df4dbad.webp 100w',
                                sizes: '(min-width: 100px) 100px, 100vw',
                              },
                              sources: [],
                            },
                            width: 100,
                            height: 100,
                          },
                        },
                      },
                      tablet: {
                        childImageSharp: {
                          gatsbyImageData: {
                            layout: 'constrained',
                            backgroundColor: '#f8f8f8',
                            images: {
                              fallback: {
                                src: '/static/e363ee14a676c0ddc8ccd8d32d73dc73/d8057/55413227825e1ab0118b05a434b7a5518df4dbad.webp',
                                srcSet:
                                  '/static/e363ee14a676c0ddc8ccd8d32d73dc73/2fa99/55413227825e1ab0118b05a434b7a5518df4dbad.webp 25w,\n/static/e363ee14a676c0ddc8ccd8d32d73dc73/dbc4a/55413227825e1ab0118b05a434b7a5518df4dbad.webp 50w,\n/static/e363ee14a676c0ddc8ccd8d32d73dc73/d8057/55413227825e1ab0118b05a434b7a5518df4dbad.webp 100w',
                                sizes: '(min-width: 100px) 100px, 100vw',
                              },
                              sources: [],
                            },
                            width: 100,
                            height: 100,
                          },
                        },
                      },
                    },
                  },
                },
                {
                  imageStructure: {
                    altText: null,
                    imageData: {
                      desktop: {
                        childImageSharp: {
                          gatsbyImageData: {
                            layout: 'constrained',
                            backgroundColor: '#f8f8f8',
                            images: {
                              fallback: {
                                src: '/static/e363ee14a676c0ddc8ccd8d32d73dc73/d8057/55413227825e1ab0118b05a434b7a5518df4dbad.webp',
                                srcSet:
                                  '/static/e363ee14a676c0ddc8ccd8d32d73dc73/2fa99/55413227825e1ab0118b05a434b7a5518df4dbad.webp 25w,\n/static/e363ee14a676c0ddc8ccd8d32d73dc73/dbc4a/55413227825e1ab0118b05a434b7a5518df4dbad.webp 50w,\n/static/e363ee14a676c0ddc8ccd8d32d73dc73/d8057/55413227825e1ab0118b05a434b7a5518df4dbad.webp 100w',
                                sizes: '(min-width: 100px) 100px, 100vw',
                              },
                              sources: [],
                            },
                            width: 100,
                            height: 100,
                          },
                        },
                      },
                      mobile: {
                        childImageSharp: {
                          gatsbyImageData: {
                            layout: 'constrained',
                            backgroundColor: '#f8f8f8',
                            images: {
                              fallback: {
                                src: '/static/e363ee14a676c0ddc8ccd8d32d73dc73/d8057/55413227825e1ab0118b05a434b7a5518df4dbad.webp',
                                srcSet:
                                  '/static/e363ee14a676c0ddc8ccd8d32d73dc73/2fa99/55413227825e1ab0118b05a434b7a5518df4dbad.webp 25w,\n/static/e363ee14a676c0ddc8ccd8d32d73dc73/dbc4a/55413227825e1ab0118b05a434b7a5518df4dbad.webp 50w,\n/static/e363ee14a676c0ddc8ccd8d32d73dc73/d8057/55413227825e1ab0118b05a434b7a5518df4dbad.webp 100w',
                                sizes: '(min-width: 100px) 100px, 100vw',
                              },
                              sources: [],
                            },
                            width: 100,
                            height: 100,
                          },
                        },
                      },
                      tablet: {
                        childImageSharp: {
                          gatsbyImageData: {
                            layout: 'constrained',
                            backgroundColor: '#f8f8f8',
                            images: {
                              fallback: {
                                src: '/static/e363ee14a676c0ddc8ccd8d32d73dc73/d8057/55413227825e1ab0118b05a434b7a5518df4dbad.webp',
                                srcSet:
                                  '/static/e363ee14a676c0ddc8ccd8d32d73dc73/2fa99/55413227825e1ab0118b05a434b7a5518df4dbad.webp 25w,\n/static/e363ee14a676c0ddc8ccd8d32d73dc73/dbc4a/55413227825e1ab0118b05a434b7a5518df4dbad.webp 50w,\n/static/e363ee14a676c0ddc8ccd8d32d73dc73/d8057/55413227825e1ab0118b05a434b7a5518df4dbad.webp 100w',
                                sizes: '(min-width: 100px) 100px, 100vw',
                              },
                              sources: [],
                            },
                            width: 100,
                            height: 100,
                          },
                        },
                      },
                    },
                  },
                },
                {
                  imageStructure: {
                    altText: null,
                    imageData: {
                      desktop: {
                        childImageSharp: {
                          gatsbyImageData: {
                            layout: 'constrained',
                            backgroundColor: '#f8f8f8',
                            images: {
                              fallback: {
                                src: '/static/e363ee14a676c0ddc8ccd8d32d73dc73/d8057/55413227825e1ab0118b05a434b7a5518df4dbad.webp',
                                srcSet:
                                  '/static/e363ee14a676c0ddc8ccd8d32d73dc73/2fa99/55413227825e1ab0118b05a434b7a5518df4dbad.webp 25w,\n/static/e363ee14a676c0ddc8ccd8d32d73dc73/dbc4a/55413227825e1ab0118b05a434b7a5518df4dbad.webp 50w,\n/static/e363ee14a676c0ddc8ccd8d32d73dc73/d8057/55413227825e1ab0118b05a434b7a5518df4dbad.webp 100w',
                                sizes: '(min-width: 100px) 100px, 100vw',
                              },
                              sources: [],
                            },
                            width: 100,
                            height: 100,
                          },
                        },
                      },
                      mobile: {
                        childImageSharp: {
                          gatsbyImageData: {
                            layout: 'constrained',
                            backgroundColor: '#f8f8f8',
                            images: {
                              fallback: {
                                src: '/static/e363ee14a676c0ddc8ccd8d32d73dc73/d8057/55413227825e1ab0118b05a434b7a5518df4dbad.webp',
                                srcSet:
                                  '/static/e363ee14a676c0ddc8ccd8d32d73dc73/2fa99/55413227825e1ab0118b05a434b7a5518df4dbad.webp 25w,\n/static/e363ee14a676c0ddc8ccd8d32d73dc73/dbc4a/55413227825e1ab0118b05a434b7a5518df4dbad.webp 50w,\n/static/e363ee14a676c0ddc8ccd8d32d73dc73/d8057/55413227825e1ab0118b05a434b7a5518df4dbad.webp 100w',
                                sizes: '(min-width: 100px) 100px, 100vw',
                              },
                              sources: [],
                            },
                            width: 100,
                            height: 100,
                          },
                        },
                      },
                      tablet: {
                        childImageSharp: {
                          gatsbyImageData: {
                            layout: 'constrained',
                            backgroundColor: '#f8f8f8',
                            images: {
                              fallback: {
                                src: '/static/e363ee14a676c0ddc8ccd8d32d73dc73/d8057/55413227825e1ab0118b05a434b7a5518df4dbad.webp',
                                srcSet:
                                  '/static/e363ee14a676c0ddc8ccd8d32d73dc73/2fa99/55413227825e1ab0118b05a434b7a5518df4dbad.webp 25w,\n/static/e363ee14a676c0ddc8ccd8d32d73dc73/dbc4a/55413227825e1ab0118b05a434b7a5518df4dbad.webp 50w,\n/static/e363ee14a676c0ddc8ccd8d32d73dc73/d8057/55413227825e1ab0118b05a434b7a5518df4dbad.webp 100w',
                                sizes: '(min-width: 100px) 100px, 100vw',
                              },
                              sources: [],
                            },
                            width: 100,
                            height: 100,
                          },
                        },
                      },
                    },
                  },
                },
              ],
            },
          ],
          link: [
            {
              label: 'Terms and conditions',
              url: '/terms-and-conditions/',
              subLinks: null,
            },
            {
              label: 'Privacy Statement',
              url: '/privacy-policy/',
              subLinks: null,
            },
            {
              label: 'Cookie Policy',
              url: '/cookie-policy/',
              subLinks: null,
            },
          ],
          lang: 'en-gb',
        },
      ],
    },
  },
};
