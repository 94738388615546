import React, { FC } from 'react';
import classNames from 'classnames';

import Button from 'components/Button';

import { HamburgerProps } from './models';

import './Hamburger.scss';

const Hamburger: FC<HamburgerProps> = ({ isOpen, onClick, className }) => {
  const hamburgerClasses = classNames('hamburger', className, {
    'hamburger--active': isOpen && isOpen !== null,
    'hamburger--initial': isOpen === null,
  });

  return (
    <Button
      ariaLabel="hamburger"
      ui={{ variant: 'simple', backgroundColor: 'transparent' }}
      className={hamburgerClasses}
      onClick={onClick}
    >
      <div className="hamburger__bar" />
    </Button>
  );
};

export default Hamburger;
