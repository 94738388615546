import React, { FC, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { Container } from 'layout';
import Logo from 'common/Logo';
import Hamburger from 'components/Hamburger';
import DesktopNavigation from 'containers/DesktopNavigation';
import MobileNavigation from 'containers/MobileNavigation';

import { toggleState } from 'utils/toggleState';
import { useCurrentLang } from 'hooks/useCurrentLang';
import { useNavigationQuery } from 'hooks/useNavigationQuery';

import { HeaderPropsType } from './model';

import './Header.scss';

const Header: FC<HeaderPropsType> = ({ brand }) => {
  const { currentLang } = useCurrentLang();
  const {
    allNavigation: { nodes },
  } = useNavigationQuery();
  const [currentLangCode] = currentLang;
  const { link, returnText } = nodes.find(({ lang }) => lang === currentLangCode);
  const [isMobileOpen, setIsMobileOpen] = useState<boolean>(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState<boolean>(false);

  const logoClasses = classNames('header__logo', 'hide--on-desktop', {
    'header__logo--active': isMobileOpen,
  });

  useEffect(() => {
    const bodyElement = window.document.body;

    if (isMobileOpen) {
      bodyElement.classList.add('scroll--prevent');
    } else {
      bodyElement.classList.remove('scroll--prevent');
    }
  }, [isMobileOpen]);

  const handleClick: () => void = () => {
    toggleState(setIsMobileOpen);
    setIsSubMenuOpen(false);
  };

  return (
    <Container
      fluid
      element="header"
      className="header background--white"
      data-testid="header-item"
    >
      <Container className="header__content background--white">
        <Link to="/" aria-label="go to home" className="header__icon" title="Senokot home page">
          <FontAwesomeIcon icon={faHouse} size="lg" className="color--grey" />
        </Link>
        <DesktopNavigation className="hide--on-mobile" {...{ link, returnText }} />
        {brand ? (
          <div className={logoClasses}>
            <Logo image={brand} />
          </div>
        ) : null}
        <Hamburger isOpen={isMobileOpen} onClick={handleClick} className="hide--on-desktop" />
      </Container>
      <MobileNavigation
        isOpen={isMobileOpen}
        setIsOpen={setIsMobileOpen}
        {...{ isSubMenuOpen, setIsSubMenuOpen, link, returnText }}
        className="hide--on-desktop"
      />
    </Container>
  );
};

export default Header;
