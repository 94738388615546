import { FADEIN, FadeInOptionsProps } from './models.d';

const { LISTING } = FADEIN;

export const returnFadeInProps = (variant: FADEIN): FadeInOptionsProps => {
  switch (variant) {
    case LISTING:
      return {
        initial: { opacity: 0, y: 0 },
        whileInView: { opacity: 1, y: 0 },
        viewport: { once: true },
        transition: {
          duration: 0.4,
          delay: 0,
        },
      };
    default:
      return {
        initial: { opacity: 0, y: -10 },
        whileInView: { opacity: 1, y: 0 },
        viewport: { once: true },
        transition: {
          duration: 0.8,
          delay: 0.2,
        },
      };
  }
};
