export const ACTIVE_CLASS_NAVIGATION = 'mobile-navigation--active';

export const NavigationMock = {
  useStaticQueryReturn: {
    allNavigation: {
      nodes: [
        {
          link: [
            {
              label: 'Our Brands',
              url: '/our-brands/',
              subLinks: [
                {
                  url: '#',
                  label: 'Main Menu',
                },
                {
                  url: '/our-brands/senokot/',
                  label: 'Senokot',
                },
                {
                  url: '/our-brands/senocalm/',
                  label: 'Senocalm',
                },
                {
                  url: '/our-brands/fybogel/',
                  label: 'Fybogel',
                },
              ],
            },
            {
              label: 'Constipation',
              url: '/constipation/',
              subLinks: [
                {
                  url: '#',
                  label: 'Main Menu',
                },
                {
                  url: '/test',
                  label: 'Overview',
                },
              ],
            },
            {
              label: '14 Day Challenge',
              url: '/test',
              subLinks: [
                {
                  url: '#',
                  label: 'Main Menu',
                },
                {
                  url: '/test',
                  label: 'Causes',
                },
              ],
            },
            {
              label: 'IBS',
              url: '/ibs/',
              subLinks: [
                {
                  url: '#',
                  label: 'Main Menu',
                },
                {
                  url: '/test',
                  label: 'Treatment',
                },
                {
                  url: '/test',
                  label: 'Further Reading',
                },
              ],
            },
            {
              label: 'Which product is right for me',
              url: '/our-brands/',
            },
            {
              label: 'Understand your gut',
              url: '/understand-your-gut/',
            },
            {
              label: 'Easy Fibre Recipes',
              url: '/easy-fibre-recipes/',
            },
          ],
          returnText: 'Main Menu',
          lang: 'en-gb',
        },
      ],
    },
  },
};
