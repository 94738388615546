import React, { FC, useState } from 'react';
import classNames from 'classnames';
import FadeIn, { FADEIN } from 'framers/FadeIn';

import { Container } from 'layout';
import Button from 'components/Button';
import Link, { LinkType } from 'components/Link';

import { LinkVariant, MobileNavigationProps } from './models.d';

import './MobileNavigation.scss';

const { MAIN, SUBMENU, RETURN } = LinkVariant;
const { LISTING } = FADEIN;

const MobileNavigation: FC<MobileNavigationProps> = ({
  link,
  isOpen,
  setIsOpen,
  className,
  isSubMenuOpen,
  setIsSubMenuOpen,
  returnText,
}) => {
  const [activeCategory, setActiveCategory] = useState<string | null>(null);

  const mobileNavigationClasses = classNames(
    'mobile-navigation',
    'background--sherwood-green',
    { 'mobile-navigation--open': isOpen },
    {
      'mobile-navigation--active': isSubMenuOpen,
    },
    className
  );

  const renderLink = (items: LinkType, type: LinkVariant): JSX.Element | null => {
    const { label, url } = items;

    switch (type) {
      case MAIN:
        return (
          <Link
            key={`${label}_main`}
            url={url}
            color="white"
            className="mobile-navigation__item"
            size={18}
            animationVariant={LISTING}
            onClick={() => {
              setIsOpen(false);
              setIsSubMenuOpen(false);
              setActiveCategory(null);
            }}
          >
            {label}
          </Link>
        );
      case SUBMENU:
        return (
          <div
            className="mobile-navigation__item mobile-navigation__item--submenu"
            key={`${label}_sub`}
          >
            <Link
              url={url}
              color="white"
              size={18}
              animationVariant={LISTING}
              onClick={() => {
                setIsOpen(false);
                setIsSubMenuOpen(false);
                setActiveCategory(null);
              }}
            >
              {label}
            </Link>
            <FadeIn animationVariant={LISTING}>
              <Button
                key={`${label}_sub`}
                ui={{
                  variant: 'menu',
                }}
                className="mobile-navigation__icon"
                ariaLabel={label}
                onClick={() => {
                  setActiveCategory(label);
                  setIsSubMenuOpen(true);
                }}
              />
            </FadeIn>
          </div>
        );
      case RETURN:
        return (
          <Button
            key={`${label}_sub`}
            ui={{
              variant: 'menu',
              space: 'unset',
              typography: { size: 18, animationVariant: LISTING },
            }}
            className="mobile-navigation__item mobile-navigation__item--button"
            ariaLabel={label}
            onClick={() => {
              setIsSubMenuOpen(false);
              setActiveCategory(null);
            }}
          >
            <span className="mobile-navigation__icon mobile-navigation__icon--return" />
            {label}
          </Button>
        );
      default:
        return null;
    }
  };

  return (
    <Container element="nav" className={mobileNavigationClasses} data-testid="mobile-navigation">
      {link?.map((item: LinkType, idx) => {
        const { label, subLinks } = item;

        return (
          <div key={`${label}_mobile`} className="mobile-navigation__container">
            {isOpen && !isSubMenuOpen ? (
              <div className="mobile-navigation__main">
                {subLinks ? renderLink(item, SUBMENU) : renderLink(item, MAIN)}
              </div>
            ) : null}
            {subLinks && label === activeCategory ? (
              <div className="mobile-navigation__submenu">
                <FadeIn animationVariant={LISTING}>
                  <button
                    type="button"
                    className="link mobile-navigation__item mobile-navigation__main-menu-link"
                    onClick={() => {
                      setIsSubMenuOpen(false);
                      setActiveCategory(null);
                    }}
                    aria-label={returnText + idx}
                  >
                    <span className="mobile-navigation__icon mobile-navigation__icon--return" />
                    {returnText}
                  </button>
                </FadeIn>
                {subLinks.map((sublink) => {
                  const { label: name } = sublink;

                  return name === returnText
                    ? renderLink(sublink, RETURN)
                    : renderLink(sublink, MAIN);
                })}
              </div>
            ) : null}
          </div>
        );
      })}
    </Container>
  );
};

export default MobileNavigation;
