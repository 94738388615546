import { ButtonProps } from '../models';

export const buttonDefaultClassesMock = [
  'button',
  'button--rounded',
  'button--space-around',
  'test-class',
];

export const ButtonChildrenMock = 'test content';

export const LinkButtonMock: ButtonProps = {
  className: 'test-class',
  ariaLabel: 'redirects to other page',
  label: ButtonChildrenMock,
  url: '/test-url/',
  ui: {
    variant: 'rounded',
    space: 'around',
    backgroundColor: 'white',
  },
};

export const FunctionButtonMock: ButtonProps = {
  className: 'test-class',
  ariaLabel: 'function after click',
  label: ButtonChildrenMock,
  ui: {
    variant: 'rounded',
    space: 'around',
    backgroundColor: 'white',
  },
};
